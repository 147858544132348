// src/components/ServicesSection.js
import React from 'react';
import { Box, Grid, Typography, Card, CardContent } from '@mui/material';
import {
  Build,
  ModelTraining,
  CloudQueue,
  School,
} from '@mui/icons-material';

const services = [
  {
    title: 'AI Consulting',
    description: 'Strategic guidance to integrate AI into your business.',
    icon: <Build fontSize="large" />,
  },
  {
    title: 'Custom Model Development',
    description: 'Developing models tailored to your data.',
    icon: <ModelTraining fontSize="large" />,
  },
  {
    title: 'AI Training',
    description: 'Empower your team with AI knowledge.',
    icon: <School fontSize="large" />,
  },
  {
    title: 'Cloud GPU Clusters',
    description: 'High-performance computing solutions.',
    icon: <CloudQueue fontSize="large" />,
  },
];

const ServicesSection = () => (
  <Box sx={{ py: 5, px: 2 }}>
    <Typography variant="h4" component="h2" align="center" gutterBottom>
      Our Services
    </Typography>
    <Grid container spacing={4}>
      {services.map((service, index) => (
        <Grid item xs={12} md={3} key={index}>
          <Card sx={{ minHeight: 200 }}>
            <CardContent sx={{ textAlign: 'center' }}>
              {service.icon}
              <Typography variant="h6" component="h3" gutterBottom>
                {service.title}
              </Typography>
              <Typography variant="body2">
                {service.description}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  </Box>
);

export default ServicesSection;

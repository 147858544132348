// src/components/HeroSection.js
import React from 'react';
import { Box, Typography, Button } from '@mui/material';

const HeroSection = () => (
  <Box
    sx={{
      height: '80vh',
      backgroundColor: '#000', // Black background
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      color: '#fff',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
      px: 2,
    }}
  >
    <Typography variant="h2" component="h1" gutterBottom>
      Empower Your Business with AI
    </Typography>
    <Typography variant="h5" component="p" gutterBottom>
      Custom AI solutions tailored to your needs.
    </Typography>
    <Button variant="contained" color="primary" size="large">
      Contact Us
    </Button>
  </Box>
);

export default HeroSection;


// src/App.js
import React from 'react';
import { CssBaseline } from '@mui/material';
import HeroSection from './components/HeroSection';
import ServicesSection from './components/ServicesSection';
import ContactSection from './components/ContactSection';

function App() {
  return (
    <>
      <CssBaseline />
      <HeroSection />
      <ServicesSection />
      <ContactSection />
    </>
  );
}

export default App;


// src/components/ContactSection.js
import React from 'react';
import { Box, Typography, TextField, Button } from '@mui/material';

const ContactSection = () => (
  <Box
    sx={{
      py: 5,
      px: 2,
      backgroundColor: '#f9f9f9',
    }}
  >
    <Typography variant="h4" component="h2" align="center" gutterBottom>
      Get in Touch
    </Typography>
    <Box
      component="form"
      sx={{
        maxWidth: 600,
        mx: 'auto',
        mt: 3,
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
      }}
    >
      <TextField label="Name" variant="outlined" required fullWidth />
      <TextField label="Email" variant="outlined" required fullWidth />
      <TextField
        label="Message"
        variant="outlined"
        required
        multiline
        rows={4}
        fullWidth
      />
      <Button variant="contained" color="primary" size="large">
        Send Message
      </Button>
    </Box>
    <Typography variant="body2" align="center" sx={{ mt: 3 }}>
      Or email us at{' '}
      <a href="mailto:support@aifindx.com">support@aifindx.com</a>
    </Typography>
  </Box>
);

export default ContactSection;
